import React, {useCallback, useContext} from 'react';
import './Login.scss';
import {ApiContext} from "../../../services/api/api-config";
import useFormState from "../../../hooks/useFormState";
import {LOGIN_STATE, LOGIN_LOADING_ID} from "tide-api";
import {useSelector} from "react-redux";
import logo from '../../../assets/images/wind-logo-hr.png';
import WindInput from "../../../components/formComponents/WindInput/WindInput";
import Button from "../../../components/utility/Button/Button";
import useBodyClass from "../../../hooks/useBodyClass";
import { Link } from 'react-router-dom';
import { paths } from "../../../services/routes/notLoggedRoutes";
import WindSwitch from "../../../components/formComponents/WindSwitch/WindSwitch";
import pckg from '../../../pckg.json';
import WindInputPassword from "../../../components/formComponents/WindInputPassword/WindInputPassword";

const Login = () => {
    useBodyClass('clear-background');
    const api = useContext(ApiContext);

    const {form, handleSimpleChange, bindSimple} = useFormState({remember: localStorage.getItem('remember')==='true'});

    const login = useCallback((e) => {
        e.preventDefault();

        // Set remember in local storage
        localStorage.setItem('remember', form.remember);

        if (!form.username || !form.pass)
            return;

        api.login(form.username, form.pass, !!form.remember).catch(console.log);
    }, [api, form]);

    // --- login state message ------
    const loginState = useSelector(({api}) => api.loggedIn);
    const loading = useSelector(({loadingIds}) => loadingIds[LOGIN_LOADING_ID] || loadingIds['Login.me']);

    let loginMsg;
    switch (loginState) {
        case LOGIN_STATE.LOGIN_ERROR:
            loginMsg = 'Lo sentimos, hubo un error al iniciar sesión.';
            break;
        case LOGIN_STATE.BAD_CREDENTIALS:
            loginMsg = 'El usuario y contraseña no coinciden';
            break;
        default:
            loginMsg = null;
            break;
    }

    return (
        <div className='Login'>

            <div className='form-block'>
                <img src={logo} alt={"Wind"} className='logo'/>
                <form onSubmit={login}>
                    <WindInput placeholder="Username" value={form.username} onChange={handleSimpleChange('username')}/><br/>
                    <WindInputPassword placeholder="Password" onChange={handleSimpleChange('pass')} value={form.pass} /><br/>
                    <Button loading={loading} type='submit' disabled={loading}>Log in</Button>
                    {loginMsg &&
                    <p className='error'>{loginMsg}</p>}
                    <div className='remember-me'>
                        <WindSwitch
                            className='project-field'
                            placeholder={'Recordarme'}
                            {...bindSimple('remember')}
                        />
                    </div>
                    <p className='forgot-password'>
                        <Link to={paths.forgotPassword} data-tooltip='Restablecer contraseña'>¿Olvidaste tu contraseña?</Link>
                    </p>
                </form>
            </div>
            <p className='version'>V {pckg.version}</p>
        </div>
    );
};

export default Login;
