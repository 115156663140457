import React, {useState, useCallback, useContext, useEffect} from 'react';
import './BankAccountFormModal.scss'
import Modal from "../../../components/utility/Modal/Modal";
import WindInput from "../../../components/formComponents/WindInput/WindInput";
import TideEntitySelect from "../../../components/utility/TideEntitySelect/TideEntitySelect";
import {ApiContext} from "../../../services/api/api-config";
import useFormState from "../../../hooks/useFormState";
import {getNotifier} from "../../../services/notifier";
import {prepareBankAccountForServer, getEmptyBankAccount, convertBankAccountToForm} from "../../../services/modelUtils/bankAccountUtils";
import Button from '../../../components/utility/Button/Button';
import useScript from '../../../hooks/useScript';
import { bankAccountListSGroups } from '../../../services/modelUtils/bankAccountUtils';

import "@paybook/sync-widget/dist/widget.css";
import SyncWidget from "@paybook/sync-widget";

const BankAccountFormModal = ({onClose, onBankAccountSaved, accountId}) => {
    useScript('https://cdn.belvo.io/belvo-widget-1-stable.js');
    
    const api = useContext(ApiContext);
    const {form, setForm, bindSimple} = useFormState( () => getEmptyBankAccount() );
    const [message, setMessage] = useState();

    const close = useCallback(() => {
        if (onClose)
            onClose()
    }, [onClose]);

    // ------ Load data ------
    useEffect(() => {
        if (accountId) {
            let id = accountId+'';
            api.bankAccounts.get({ id,  params:{sGroups: bankAccountListSGroups}})
                .then( accounts => {setForm( convertBankAccountToForm(accounts) )} );
        }
    }, [accountId, api, setForm]);

    const saveBankAccount = useCallback(() => {
        let transaction;

        try {
            transaction = prepareBankAccountForServer({
                ...form
            });
        } catch (e) {
            return getNotifier().warning(e.message);
        }
        if ( accountId === 0 ) {
            api.bankAccounts.create({params: transaction})
            .then(() => {
                getNotifier().success('La cuenta ha sido creada correctamente');
                if(onBankAccountSaved){
                    onBankAccountSaved();
                }
                if(onClose){
                    onClose();
                }
            });
        }
        else {
            getNotifier().error('La cuenta no puede ser guardada');
        }
    }, [form, api, onClose, onBankAccountSaved, accountId]);

    const saveLinkBelvo = useCallback((link, institution) => {
        let parameters = {
            belvoLink: link,
            belvoInstitution: institution
        };
        
        let id = accountId+'';
        api.bankAccounts.update({id, params: parameters})
            .then(() => {
                getNotifier().success('La cuenta se ha conectado correctamente');
                onClose();
            });
    }, [api,onClose, accountId ]);

    const onExit = useCallback((data) => {
        setMessage("Saliste de la aplicación, para conectar da click en el botón:");
    }, []);

    const onEvent = useCallback((event) => {
        
    }, []);
    
    const showConnectBelvo = useCallback((token) => {
        // config options: 
        // https://developers.belvo.com/docs/widget-startup-configuration
        // for default generated links are recurring
        const config = {
            callback: (link, institution) => saveLinkBelvo(link, institution),
            onExit: (data) => onExit(data),
            onEvent: (data) => onEvent(data),
            resources: ["ACCOUNTS", "OWNERS", "TRANSACTIONS"],
            access_mode: "recurrent",
            show_abandon_survey: false,
            external_id: `WIND-${accountId}`  // local user id -> 
        }
        window.belvoSDK.createWidget(token.access, config).build();
    }, [saveLinkBelvo, onEvent, onExit, accountId]);

    const saveSyncfy = useCallback((credential) => {
        let parameters = {
            syncfyCredential: credential
        };
        
        let id = accountId+'';
        api.bankAccounts.update({id, params: parameters})
            .then(() => {
                getNotifier().success('La cuenta se ha conectado correctamente');
                onClose();
            });
    }, [api,onClose, accountId]);

    const showConnectSyncfy = useCallback((response) => {
        // config options:
        // https://syncfy.com/w/en/sync/public/app/(section:docs/mx/sync/widget/config)
        let div = document.createElement("div");
        div.id = "syncfy";
        document.body.appendChild(div);
        let token = response.token;
        const config = {
            token,
            element: "#syncfy",
            config: {
                "locale": "es",
                "entrypoint": {  "country": "MX"  },
                "navigation": {
                    "displayBusinessSites": true,
                    "displayLogoImages": true,
                    "displayPersonalSites": true,
                    "toastDuration": 6000,
                    "toastPosition": "top-right"
                }
            }
        };
        let syncWidget = new SyncWidget(config);
        syncWidget.open();
        syncWidget.$on( "success", (resp) => {
            syncWidget.close();
            saveSyncfy( resp.id_credential );
        } );
    }, [saveSyncfy]);

    const unlinkSyncfy = useCallback(()=>{
        let parameters = {
            clabe: form.clabe,
            syncfyCredential: null,
        };
        let id = accountId+'';
       
        api.bankAccounts.update({id, params: parameters})
        .then(() => {
            getNotifier().success('La cuenta se ha desconectado correctamente');
            onClose();
        });        
    } ,[api,onClose, accountId, form])

    const unlinkBelvo = useCallback(() => {
        let parameters = {
            clabe: form.clabe,
            belvoLink: null,
            belvoInstitution: null
        };
        
        let id = accountId+'';
        api.bankAccounts.update({id, params: parameters})
            .then(() => {
                getNotifier().success('La cuenta se ha desconectado correctamente');
                onClose();
            });
    }, [api,onClose, accountId, form]);
    
    return (
        <Modal className={'BankAccountFormModal'}
               onClose={close}
               secondaryButtonAction={close}
               mainButtonAction={ saveBankAccount }
               mainButtonText="Guardar"
        >
            <div className='BankAccountForm'>
                <form onSubmit={(e)=>{e.preventDefault()}}>
                    <div className='form-container'>
                        <h2 className="SceneTitle modal-title secondary">
                            <span className="title-content">Agregar nueva cuenta</span>
                        </h2>

                        <div className='form-row mt-20'>
                            <WindInput
                                placeholder="Agrega un nombre a la cuenta"
                                {...bindSimple('name')}
                            />

                            <TideEntitySelect
                                className='project-field'
                                entity='banks'
                                placeholder={"Selecciona un banco"}
                                autoSelect={false}
                                additionalFilters={{'pagination': false}}
                                {...bindSimple('bank')}
                            />
                            
                        </div>
                        <div className='form-row mt-20'>
                            <WindInput
                                placeholder="Agrega el número de cuenta la cuenta "
                                {...bindSimple('number')}
                            />
                            <WindInput
                                placeholder="Agrega cuenta clabe de la cuenta "
                                {...bindSimple('clabe')}
                            />
                        </div>
                        <div>{message}</div>
                        {   (!!accountId && !form.belvoLink && !form.syncfyCredential) &&
                            <div>
                                <div className='form-row mt-20'>
                                    <Button onClick={() => api.belvo.generateToken().then(showConnectBelvo)}>Conectar con Belvo</Button>
                                </div>
                                <div className='form-row mt-20'>
                                    <Button onClick={() => api.syncfy.getToken({params: { bank_account: accountId }}).then(showConnectSyncfy)}>Conectar con Syncfy</Button>
                                </div>
                            </div>
                        }

                        {   (!!form.belvoLink) &&
                            <div className='form-row mt-20'>
                                <Button  style={{ backgroundColor: 'red', color: 'white', border: 'none' }} onClick={ () => unlinkBelvo() }>Desconectar de Belvo</Button>
                            </div>
                        }
                        {   (!!form.syncfyCredential) &&
                            <div className='form-row mt-20'>
                                <Button  style={{ backgroundColor: 'red', color: 'white', border: 'none' }} onClick={ () => unlinkSyncfy() }>Desconectar de Syncfy</Button>
                            </div>
                        }
                            
                        
                    </div>
                </form>
                <div id="belvo" />
            </div>
        </Modal>
    );
};

export default BankAccountFormModal;
