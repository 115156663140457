import moment from "moment";
import ClientInfo from "../../scenes/crm/ClientDetail/components/ClientInfo/ClientInfo";
import {Link} from "react-router-dom";
import ClientTransactions from "../../scenes/crm/ClientDetail/components/ClientTransactions/ClientTransactions";
import ClientUsers from "../../scenes/crm/ClientDetail/components/ClientUsers/ClientUsers";
import { moneyFormatter } from "../currencyUtils";

import { copyPropertyOrNull, copyPropertyToOrThrow, copyPropertyOrThrow } from "../formUtils";
import { paths } from "../../services/routes/appRoutes";
import { ClientAccountStatement } from "../../scenes/crm/ClientDetail/components/ClientAccountStatement/ClientAccountStatement";
import ClientProjects from "../../scenes/crm/ClientDetail/components/ClientProjects/ClientProjects";
import {displayDate} from "../jsUtils";

export const prepareClientForServer = ( form )=>{
    let client = {}

    if(!form.isLegalPerson)
        copyPropertyToOrThrow( form, client, "clientName", "Por favor llena el campo nombre de cliente", "name" );
    else {
        copyPropertyToOrThrow( form, client, "companyName", "Por favor indica la razón social", "companyName" );
        client.name = form.companyName;
        client.fullName = form.companyName;
    }
        
    
    copyPropertyOrNull( form, client, "lastName");
    copyPropertyOrNull( form, client, "secondLastName" );
    copyPropertyOrNull( form, client, "companyName" );
    copyPropertyOrNull( form, client, "isLegalPerson" );
    copyPropertyOrThrow( form, client, "initials", "Por favor llena el campo iniciales" );
    copyPropertyOrNull( form, client, "isLegalPerson" );
    //copyPropertyOrNull( form, client, "street" + "externalNumber" + "internalNumber","address");
    copyPropertyOrNull( form, client, "email" );
    copyPropertyOrNull( form, client, "phone" );
    copyPropertyOrNull( form, client, "zip" );
    copyPropertyOrNull( form, client, "brandingMainColor" );
    copyPropertyOrNull( form, client, "brandingAccentColor" );

    client.address = form.street + " " + form.externalNumber + " " + form.internalNumber;

    if(form.addContactInfo){
        client.users = [prepareUserForServer(form)];
    }

    client.fiscalData = prepareFiscalDataForServer(form?.fiscalData);

    client.sendAccess = !!form.sendAccess;

    return client;
}

export const prepareClientForServerUpdate = ( form )=>{
    let client = {}

    copyPropertyToOrThrow( form, client, "clientName", "Por favor llena el campo nombre de cliente", "name" );
    copyPropertyOrNull( form, client, "lastName" );
    copyPropertyOrNull( form, client, "secondLastName" );
    copyPropertyOrNull( form, client, "companyName" );
    copyPropertyOrNull( form, client, "isLegalPerson" );
    copyPropertyOrThrow( form, client, "initials", "Por favor llena el campo iniciales");
    copyPropertyOrNull( form, client, "isLegalPerson" );
    //copyPropertyOrNull( form, client, "street" + "externalNumber" + "internalNumber");
    copyPropertyOrNull( form, client, "email" );
    copyPropertyOrNull( form, client, "phone");
    copyPropertyOrNull( form, client, "zip" );
    copyPropertyOrNull( form, client, "brandingMainColor" );
    copyPropertyOrNull( form, client, "brandingAccentColor" );

    client.address = form.street + " " + form.externalNumber + " " + form.internalNumber;
    client.companyOwnerId = form.companyOwnerId?.id;

    client.fiscalData = prepareFiscalDataForServer(form.fiscalData);

    return client;
}

export const prepareUserForServer = ( form )=>{
    let user = {};

    copyPropertyOrThrow( form, user, "name", "Por favor llena el campo de nombre" );
    copyPropertyToOrThrow( form, user, "lastname", "Por favor llena el campo de apellidos", "lastName" );
    copyPropertyOrThrow( form, user, "email", "Por favor llena el campo de correo" );
    copyPropertyOrNull( form, user, "phone");
    copyPropertyToOrThrow( form, user, "email", "Por favor llena el campo de correo electrónico", "username" );
    copyPropertyToOrThrow( form, user, "email", "Por favor llena el campo de correo electrónico", "password" );
    user.isCompanyOwner = true;

    return user;
}

export const prepareFiscalDataForServer = ( form )=>{
    let fiscalData = {};

    if(!form)
        return fiscalData;

    copyPropertyOrNull( form, fiscalData, "email");
    copyPropertyOrNull( form, fiscalData, "businessName" );
    copyPropertyOrNull( form, fiscalData, "rfc");
    copyPropertyOrNull( form, fiscalData, "comercialName");
    copyPropertyOrNull( form, fiscalData, "street" );
    copyPropertyOrNull( form, fiscalData, "externalNumber" );
    copyPropertyOrNull( form, fiscalData, "internalNumber" );
    copyPropertyOrNull( form, fiscalData, "neighborhood" );
    copyPropertyOrNull( form, fiscalData, "city" );
    copyPropertyOrNull( form, fiscalData, "state" );
    copyPropertyOrNull( form, fiscalData, "zip" );
    copyPropertyOrNull( form, fiscalData, "country" );
    copyPropertyOrNull( form, fiscalData, "comercialName" );
    copyPropertyOrNull( form, fiscalData, "delegation" );
    
    fiscalData.isDefault = true;

    return fiscalData;
}

export const prepareServerToForm = (client) => {
    let form = {...client, clientName: client.fullName, companyOwnerId: client.companyOwner, lastName: client.lastName, secondLastName: client.secondLastName};
    return form;
}

export const clientTableColumns = [
    {
        Header: 'Nombre',
        accessor: (client) =>
            <Link to={paths.clientDetail.replace(":id", client.id)} data-tooltip={"Ver detalles de cliente"}>
                {client.fullName}
            </Link>,
        id: 'clientName',
    },
    {
        Header: 'Contacto',
        accessor: 'companyOwner.fullName'
    },
    {
        Header: 'Proyectos aprobados',
        accessor: (prj) => <span className="td-quty">{prj.clientStats.approvedProjectsCount}</span>
    },
    {
        Header: 'Proyectos totales',
        accessor: (prj) => <span className="td-quty">{prj.clientStats.projectsCount}</span>
    },
    {
        Header: 'Pagado',
        id: 'pagado',
        accessor: (q) => `$${moneyFormatter(Number(q.clientStats.paymentsSum))}`
    },
    {
        Header: 'Pendiente',
        id: 'pendiente',
        accessor: (q) => `$${moneyFormatter(Number(q.clientStats.pendingPaymentsSum))}`
    },
];

export const clientUsersTableColumns = [
    {
        Header: 'Nombre',
        accessor: 'name',
    },
    {
        Header: 'Empresa',
        accessor: 'client.fullName'
    },
    {
        Header: 'Email',
        accessor: 'email'
    },
    {
        Header: 'Teléfono',
        accessor: 'phone'
    },
    {
        Header: 'Usuario',
        accessor: 'username'
    }
];

export const clientTransactionsTableColumns = [
    { Header: 'Fecha', accessor: (transaction) => moment(transaction.transactionDate).format('DD/MM/YYYY') },
    { Header: 'Cantidad', accessor: (transaction) => `$${moneyFormatter(Number(transaction.amount))}` },
    { Header: 'Cotizaciones', accessor: 'quotesFolios' },
    { Header: 'Cuenta de pago', accessor: (transaction) => transaction.bankAccount?.name ? transaction.bankAccount?.name : 'Efectivo' },
];

export const clientTabs = [
    { value: 'information', label: 'Información general', Component: ClientInfo },
    { value: 'projects', label: 'Proyectos', Component: ClientProjects },
    { value: 'accountStatement', label: 'Estado de cuenta', Component: ClientAccountStatement },
    { value: 'transactions', label: 'Pagos', Component: ClientTransactions },
    { value: 'directory', label: 'Usuarios', Component: ClientUsers },
];

export const getClientTabs = (security) => {
    const canAdminProjects = security.canAdminProjects();
    const canAdminTransactions = security.canAdminTransactions();
    const canAdminQuotes = security.canAdminQuotes();
    const canAdminUsers = security.canAdminUsers();

    const permissions = {
        projects: canAdminProjects,
        transactions: canAdminTransactions,
        directory: canAdminUsers,
        accountStatement: canAdminQuotes
    };

    const filteredTabs = clientTabs.filter(tab => {
        if (tab.value in permissions) {
            return permissions[tab.value];
        }
        return true;
    });

    return filteredTabs;
};

export const clientUsersTableSGroups = [
    'user_read',
    'user_read_position',
    'user_read_client',
    'client_read_name'
];

export const clientQuotesTableColumns = [
    { Header: 'Proyecto', accessor: 'project.name' },
    { Header: 'Nombre', accessor: 'title'},
    {
        Header: 'Folio C.',
        id: 'folio',
        accessor: (q) =>
            <Link to={paths.quoteDetail.replace(":id", q?.id)} data-tooltip="Ver detalles de la cotización" data-test-id="see-quote-detail-link">
                {q.folio}
            </Link>, },
    { Header: 'Factura', id: 'trackingNumber', accessor: (q) => q.folio },
    { Header: 'Creación', id: 'createdDate', accessor: (q) => displayDate(q.createdDate) },
    { Header: 'Pendiente', id: 'pendiente', accessor: (q) => getPendingPaymentsSumString(q) },
    { Header: 'Total', id: 'total', accessor: (q) => `$${moneyFormatter(Number(q.total))}` },
];


const getPendingPaymentsSumString = (quote) => {
    const pending = quote.total - getPaymentsSum(quote);
    return `$${moneyFormatter(Number(pending))}`
}

// const getPaymentsSumString = (quote) => {
//     const total = getPaymentsSum(quote);
//     return `$${moneyFormatter(Number(total))}`
// }

const getPaymentsSum = (quote) => {
    return quote.quoteTransactions.map(t => t.amount).reduce((prev, curr) => Number(prev) + Number(curr), 0);
}

export const clientTableSGroups = [
    'client_read_id',
    'client_read_last_name',
    'client_read_second_last_name',
    'client_read_full_name',
    'client_read_initials',
    'client_read_name',
    'client_read_phone',
    'client_read_zip',
    'client_read_company_owner',
        'user_read_full_name',
        'user_read_is_company_owner',
    'client_read_client_stats',
        'client_stats_read_approved_projects_count',
        'client_stats_read_projects_count',
        'client_stats_read_payments_sum',
        'client_stats_read_pending_payments_sum',
    'client_read_fiscal_data',
        //'fiscal_data_read',
        'fiscal_data_read_id',
        'fiscal_data_read_rfc',
        'fiscal_data_read_business_name',
        'fiscal_data_read_email',
        'fiscal_data_read_comercial_name',
        'fiscal_data_read_street',
        'fiscal_data_read_external_number',
        'fiscal_data_read_internal_number',
        'fiscal_data_read_neighborhood',
        'fiscal_data_read_city',
        'fiscal_data_read_state',
        'fiscal_data_read_zip',
        'fiscal_data_read_country',
        'fiscal_data_read_delegation'
];

export const clientDetailSGroups = [
    'client_read_id',
    'client_read_initials',
    'client_read_name',
    'client_read_phone',
    'client_read_zip',
    'client_read_last_name',
    'client_read_second_last_name',
    'client_read_full_name',
    'client_read_company_name',
    'client_read_company_owner',
    'client_read_is_legal_person',
    'client_read_main_contact',
        'user_read',
        'user_read_id',
        'user_read_full_name',
        'user_read_name',
        'user_read_last_name',
        'user_read_phone',
        'user_read_email',
        'user_read_username',
    'client_read_client_stats',
        'client_stats_read_approved_projects_count',
        'client_stats_read_projects_count',
        'client_stats_read_all_quotes_sum',
        'client_stats_read_approved_quotes_sum',
        'client_stats_read_payments_sum',
        'client_stats_read_pending_payments_sum',
        'client_stats_read_approved_quotes_count',
        'client_stats_read_pending_quotes_count',
        'client_stats_read_quotes_count',
    'client_read_fiscal_data',
        //'fiscal_data_read',
        'fiscal_data_read_id',
        'fiscal_data_read_rfc',
        'fiscal_data_read_business_name',
        'fiscal_data_read_email',
        'fiscal_data_read_comercial_name',
        'fiscal_data_read_street',
        'fiscal_data_read_external_number',
        'fiscal_data_read_internal_number',
        'fiscal_data_read_neighborhood',
        'fiscal_data_read_city',
        'fiscal_data_read_state',
        'fiscal_data_read_zip',
        'fiscal_data_read_country',
        'fiscal_data_read_delegation',
    'client_read_branding_logo',
        'app_file_read',
    'client_read_branding_main_color',
    'client_read_branding_accent_color',
];

export const clientQuotesSGroups = [
    'quote_read',
    'quote_read_quote_transactions',
        'quote_transaction_read_amount',
        'quote_transaction_read_transaction',
            'transaction_read_folio',
    'quote_read_project',
        'project_read_id',
        'project_read_name',
    'client_read_fiscal_data',
        //'fiscal_data_read',
        'fiscal_data_read_id',
        'fiscal_data_read_rfc',
        'fiscal_data_read_business_name',
        'fiscal_data_read_email',
        'fiscal_data_read_comercial_name',
        'fiscal_data_read_street',
        'fiscal_data_read_external_number',
        'fiscal_data_read_internal_number',
        'fiscal_data_read_neighborhood',
        'fiscal_data_read_city',
        'fiscal_data_read_state',
        'fiscal_data_read_zip',
        'fiscal_data_read_country',
        'fiscal_data_read_delegation'
]
